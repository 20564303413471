$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';
$primary: #e15200;
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

html, body {
    height: 100%;
}

.py-10 {
    padding: 5rem 0;
}

.fixed-br {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;
}

.btn-outline-primary:hover {
    color: white;
}

.white-placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white !important;
    opacity: 1; /* Firefox */
}

.white-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white !important;
}

.white-placeholder::-ms-input-placeholder { /* Microsoft Edge */
    color: white !important;
}

.htmx-indicator{
    opacity: 0;
    transition: opacity 500ms ease-in;
}
.htmx-request .htmx-indicator{
    opacity: 1;
}
.htmx-request.htmx-indicator{
    opacity: 1;
}

.us-orange {
    color: #ec6e10;
}

.bg-us-orange {
    background-color: #ec6e10;
}

.us-cyan {
    color: #009ba6;
}

.bg-us-cyan {
    background-color: #009ba6;
}

.us-yellow {
    color: #fab900;
}

.bg-us-yellow {
    background-color: #fab900;
}

.mt-busca {
    margin-top: 3.5rem;
}
